<template>
  <div :class="['card', desktopAuto ? 'card-desktop' : '', item.name === 'Unterkünfte' ? 'unterkunft-card' : '']" @click="categoryChanged">
    <div class="card-image" :style="getStyle"></div> 
    <div class="card-title">
      <h5>{{ item.name }}</h5>
      <h6 class="info-text">{{ item.info }}</h6>
    </div>
  </div>
</template>

<script>

  import { getCategoryPlaceholderImage } from '@/utils/tripmeister-helpers';

  export default {
    name: 'CategoryCard',
    props: {
      desktopAuto: {
        type: Boolean,
      default: false
      },
      item: {
        type: Object,
        required: true
      }
    },
    computed: {
      getStyle(){
        return 'backgroundImage: url("'+this.getCategoryPlaceholderImage(this.item.name)+'")';
      }
    },
    methods: {
     getCategoryPlaceholderImage,
     categoryChanged(){
      var categories = [];
      categories.push(this.item.name);
      this.$store.commit('SET_SELECTED_CATEGORIES',categories);
      if(this.item.name !== 'Unterkünfte'){
        this.$router.push({ name: 'LocationKarte', params: {destination: "all"}, query: {categories: categories} });
      }
      else {
        this.$router.push({ name: 'Unterkünfte'});
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .card {
    position: relative;
    border: none;
    box-shadow: 2px 2px 5px rgba($color: $dark-color, $alpha: 0.35);
    border-radius: 15px;
    min-width: 200px;
    width: 240px;
    margin-right: 8px;
    margin-bottom: 10px;

    &-desktop {
      width: 100%;
      @include responsive($scroll-breakpoint) {
        width: 200px;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    @include responsive($scroll-breakpoint) {
      &:first-child {
        margin-left: 15px;
      }
      &:last-child {
        margin-right: 15px;
      }
    }

    &-image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      max-width: 100%;
      min-height: 150px;
      max-height: 150px;
      overflow: hidden;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;

      img {
        min-width: 100%;
        height: 150px;
        width: auto;
      }
    }

    &-title {
      padding: 1rem 0.5rem;
      margin-bottom: 0;

      h5 {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 5px;
      }

      .info-text {
        margin-top: auto;
        font-size: 12px;
        text-transform: uppercase;
        color: $primary;
      }
    }
  }

  .unterkunft-card {
    .card-image {
      background-size: contain;
    }
  }
</style>
